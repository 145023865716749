const PDF_LIST = [
  {
    title: 'গানের বিশ্বকর্মা',
    publisher: 'শতবর্ষে সলিল, বেহালা সাঙ্গীতিক',
    outline: 'Tribute to Salil Chowdhury',
    pdf: '11dz_A3xDcdIlYCjCnD_pNVCE15vYQlJ8',
    preview: 'gaaner-biswakarma__preview.png'
  },
  {
    title: 'মনখারাপের পাড়া (১ম খন্ড)',
    publisher: 'নির্মুখোশ, শারদ ১৪২৯',
    outline: 'Down the memory lane',
    pdf: '1DNfetdzkA1b09DfJpQr8vkcxcQr4lI--',
    preview: 'mon-kharaper-para-1__preview.png'
  },
  {
    title: 'মনখারাপের পাড়া (২য় খন্ড)',
    publisher: 'নির্মুখোশ, শারদ ১৪৩০',
    outline: 'Down the memory lane',
    pdf: '11BZ65UppJOlzi528-A7tJCUSquOcDfXx',
    preview: 'mon-kharaper-para-2__preview.png'
  },
  {
    title: 'ষোলো-আনা জীবনের দিকে',
    publisher: 'মেস-হোস্টেল ঘটিত এ বাঙালি জীবন',
    outline: 'Life at Mess-Hostel-Boarding',
    pdf: '1blTK3MSYwX5mFg5AkDSecZfV7bn352zd',
    preview: 'sholo-aana-jiboner-dike__preview.png'
  }
]

export default PDF_LIST
